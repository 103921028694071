<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col cols="12">
      <div class="fn24-weight7">Admin Roles</div>
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="search" outlined dense></v-text-field>
    </v-col>
    <v-spacer />
    <v-col cols="4" class="text-right">
      <v-btn
        dark
        class="rounded-xl px-12"
        width="171"
        height="39"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
        ><v-icon>mdi-plus</v-icon>Add New Admin</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card
        ><v-data-table
          :headers="headersCategory"
          :items="items"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.firstName }} {{ item.lastName }}</span>
          </template>
          <template v-slot:[`item.activeFlag`]="{ item }">
            <v-icon v-if="item.activeFlag" dense color="green"
              >mdi-check</v-icon
            >
            <v-icon v-if="!item.activeFlag" dense color="red">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.action`]>
            <v-row justify="center">
              <!-- <v-icon @click="viewUser(item)">mdi-eye</v-icon> -->
              <v-icon>mdi-pencil</v-icon>
              <v-icon>mdi-delete</v-icon>
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <!-- <v-row class="mt-4" align="center" justify="end">
      <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
      <v-btn @click="submit()" color="primary">บันทึก</v-btn>
    </v-row> -->
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      search: "",
      headersCategory: [
        // { text: "ลำดับ", value: "count", align: "center" },
        // { text: "username", value: "username", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        // { text: "หมายเลขโทรศัพท์", value: "telNumber", align: "center" },
        // { text: "Line", value: "line", align: "center" },
        // { text: "Email", value: "email", align: "center" },
        { text: "Roles", value: "role", align: "center" },
        { text: "", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [
        {
          firstName: "jaidee",
          activeFlag: true,
          role: "admin",
        },
        {
          firstName: "jaidee1",
          activeFlag: true,
          role: "admin",
        },
        {
          firstName: "jaidee2",
          activeFlag: false,
          role: "admin",
        },
      ],
      brandName: "",
      brandDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      productOptionList: [],
    };
  },
  created() {
    this.getAllAdmin();
  },
  methods: {
    async getAllAdmin() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/
          `,
        auth
      );
      console.log("getAllAdmin", response.data);
      // this.items = response.data.data;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageUser");
    },
    async addproductOptionList() {
      this.productOptionList.push({
        weightForm: this.weightForm,
        weightTo: this.weightTo,
        weightPrice: this.weightPrice,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          brandName: this.brandName,
          description: this.brandDescription,
          activeFlag: this.activeFlag,
          brandImg: this.pic1,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/brands`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างการจัดส่งสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageUser");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
